<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import AddBatch from "@/router/views/dashboards/batch/add-batch";
import DetailSettingJadwal from "@/router/views/dashboards/setting-jadwal/detail-setting-jadwal";
import axios from "axios";
import Swal from "sweetalert2";
// import Swal from "sweetalert2";

export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    AddBatch,
    DetailSettingJadwal,
  },
  data() {
    return {
      title: "List Jadwal Pelaksanaan",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Setting Jadwal",
          href: "/setting-jadwal",
          active: true,
        },
      ],
      isShowForm: false,

      idjadwal: this.$route.params.id,
      dataJadwal: [],
      dataSimulasi: {},

      simulasiData: {},
      currentSimulasiId: 0,
      printSimulasi: true,
      simulasi_id: 0,

      loadingTableJadwal: true,
      loadingTableSimulasi: true,
      currentKeyDetailComponent: [],
      is_lock_jadwal: true
    };
  },
  mounted() {
    this.getJadwal();
    this.getSimulasi();
  },
  methods: {
    getJadwal(
      url = process.env.VUE_APP_BACKEND_URL_VERSION +
        "dashboard/jadwal/" +
        this.idjadwal
    ) {
      let self = this;
      let config = {
        method: "get",
        url: url,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };
      axios(config)
        .then((response) => {
          let response_data = response.data;
          self.dataJadwal = response_data.data;
          if (self.dataJadwal.j_is_lock == 1) {
            self.is_lock_jadwal = true;
          } else {
            self.is_lock_jadwal = false;
          }
          self.loadingTableJadwal = false;
        })
        .catch(() => {

        });
    },
    getSimulasi(
      url = process.env.VUE_APP_BACKEND_URL_VERSION +
        "dashboard/jadwal/" +
        this.idjadwal +
        "/simulasi-new"
    ) {
      let self = this;
      let config = {
        method: "get",
        url: url,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };
      axios(config)
        .then((response) => {
          //
          let response_data = response.data;
          self.dataSimulasi = response_data.data;
          self.loadingTableSimulasi = false;
        })
        .catch(() => {

        });
    },
    showModalMateri(simulasi, simulasi_id) {
      let self = this;
      self.isShowModal = true;
      self.simulasiData = simulasi;
      self.simulasi_id = simulasi_id;
    },
    isCetakSimulasi(simulasi_id) {
      let self = this;
      if (simulasi_id != self.currentSimulasiId) {
        self.currentSimulasiId = simulasi_id;
        self.printSimulasi = true;
        return true;
      }
      self.printSimulasi = false;
      return false;
    },
    showDetailComponent(index) {
      let self = this;
      if (!self.currentKeyDetailComponent.includes(index)) {
        self.currentKeyDetailComponent.push(index);
      }
    },

    lockJadwal(status) {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Apakah anda yakin?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fas fa-spinner fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "put",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "dashboard/lock",
            data: {
              status: status,
              id_jadwal: self.dataJadwal.idjadwal,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then(() => {
            let timerInterval;
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Berhasil.",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
              didOpen: () => {
                timerInterval = setInterval(() => {
                  const content = Swal.getContent();
                  if (content) {
                    const b = content.querySelector("b");
                    if (b) {
                      b.textContent = Swal.getTimerLeft();
                    }
                  }
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                self.getJadwal();
                self.getSimulasi();
              }
            });
          }).catch(function (error) {
            if (error.response) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.response.data.meta.message,
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Terjadi kesalahan pada sistem.",
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
  },
};
</script>

<template>
  <Layout>
    <template v-if="!isShowForm">
      <PageHeader
        :title="title"
        :items="items"
      />
      <div class="row">
        <div class="col-12 d-flex justify-content-end">
          <button
            class="btn btn-warning btn-sm"
            @click="lockJadwal(1)"
            v-if="is_lock_jadwal == false"
          >
            <i class="fa fa-lock"></i>
            Kunci Jadwal
          </button> &nbsp;
          <button
            class="btn btn-success btn-sm"
            @click="lockJadwal(0)"
            v-else-if="is_lock_jadwal"
          >
            <i class="fa fa-unlock"></i>
            Open Jadwal
          </button> &nbsp;
          <router-link
            :to="{
              name: 'all-batch',
              params: {
                idJadwal: idjadwal,
              },
              query: {
                level: dataJadwal.j_materi,
              },
            }"
            class="btn btn-info btn-sm"
          >
            <i class="fa fa-list"></i> List Batch
          </router-link>
          &nbsp;
          <button
            class="btn btn-secondary btn-sm"
            v-if="is_lock_jadwal"
          >
            <i class="fa fa-plus"></i>
            Tambah Batch
          </button>
          <button
            class="btn btn-primary btn-sm"
            @click="isShowForm = true"
            v-else
          >
            <i class="fa fa-plus"></i>
            Tambah Batch
          </button>
        </div>
        <div class="col-12 mt-2">
          <table class="table mb-0 table-bordered table-condensed table-hover">
            <thead class="bg-dark text-center text-white">
              <tr>
                <th>Jadwal ID</th>
                <th>Tanggal</th>
                <th>Jenis Asesmen</th>
                <th>Petugas Kelas</th>
                <th>Unit Pelaksana</th>
                <th>Jumlah Asesor</th>
                <th>Jumlah Asesi</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="loadingTableJadwal">
                <td colspan="8">
                  <i class="fas fa-spinner fa-spin"></i> Loading...
                </td>
              </tr>
              <tr v-else-if="dataJadwal == ''">
                <td
                  class="text-center"
                  colspan="8"
                >Data Tidak Tersedia</td>
              </tr>
              <tr v-else>
                <td>{{ dataJadwal.idjadwal }}</td>
                <td>
                  {{ dataJadwal.j_tanggal_mulai }} s/d
                  {{ dataJadwal.j_tanggal_sampai }}
                </td>
                <td>{{ dataJadwal.j_txt_dirkom }}</td>
                <td>{{ dataJadwal.j_txt_ptl }}</td>
                <td>{{ dataJadwal.j_txt_unit }}</td>
                <td>{{ dataJadwal.jumlahAsesor }}</td>
                <td>{{ dataJadwal.jumlahAsesi }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row">
        <div class="col-12 mt-2 mb-2">
          <hr />
          <div class="table-responsive">
            <table class="table mb-0 table-bordered table-condensed table-hover">
              <thead class="bg-dark text-center text-white">
                <tr>
                  <th
                    class="text-center"
                    width="50px"
                  >No</th>
                  <th class="text-center">Nama Simulasi</th>
                  <th
                    class="text-center"
                    width="150px"
                  >Kelompok</th>
                  <th
                    class="text-center"
                    width="60%"
                  >Detail</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="loadingTableSimulasi">
                  <td colspan="4">
                    <i class="fas fa-spinner fa-spin"></i> Loading...
                  </td>
                </tr>
                <tr v-else-if="dataSimulasi == ''">
                  <td
                    class="text-center"
                    colspan="4"
                  >Data Tidak Tersedia</td>
                </tr>
                <tr
                  v-else
                  v-for="(simulasi, key) in dataSimulasi"
                  :key="key"
                >
                  <td
                    v-if="simulasi.is_render"
                    :rowspan="simulasi.kelompok == 'Kelompok A'
                      ? Object.keys(simulasi.jumlah_kelompok).length
                      : 0
                      "
                    class="text-center"
                  >
                    {{ simulasi.urutan }}
                  </td>
                  <td
                    v-if="simulasi.is_render"
                    :rowspan="simulasi.kelompok == 'Kelompok A'
                      ? Object.keys(simulasi.jumlah_kelompok).length
                      : 0
                      "
                  >
                    {{ simulasi.nama_simulasi }}
                  </td>
                  <td>{{ simulasi.kelompok }}</td>
                  <td>
                    <div
                      class="accordion"
                      role="tablist"
                    >
                      <a
                        href="#"
                        v-b-toggle="'accordion-' + key"
                        @click="showDetailComponent(key)"
                      >
                        View Data <i class="bx bxs-down-arrow"></i>
                      </a>
                      <b-collapse
                        :id="'accordion-' + key"
                        accordion="my-accordion"
                        role="tabpanel"
                        class="mt-2"
                      >
                        <DetailSettingJadwal
                          v-if="
                            currentKeyDetailComponent.includes(key)
                              ? true
                              : false
                          "
                          :idJadwal="idjadwal"
                          :simulasiId="simulasi.simulasi_id"
                          :simulasiNama="simulasi.nama_simulasi"
                          :jenisAsesmen="simulasi.jenis_asesmen"
                          :idMateri="simulasi.id_materi"
                          :jenisMateri="simulasi.jenis_materi"
                          :tipeMateri="simulasi.tipe_materi"
                          :kelompokNama="simulasi.kelompok"
                          :tipePenjadwalan="simulasi.tipe_penjadwalan"
                          :jumlahSoal="simulasi.jumlah_soal"
                          :tanggalMulai="simulasi.tanggal_mulai"
                          :tanggalSelesai="simulasi.tanggal_selesai"
                          :tanggalMulaiJadwal="dataJadwal.j_tanggal_mulai"
                          :tanggalSelesaiJadwal="dataJadwal.j_tanggal_sampai"
                          :detailAsesor="simulasi.detail_asesor"
                          :jadwalMateri="dataJadwal.j_materi"
                          :isLockJadwal="is_lock_jadwal"
                        ></DetailSettingJadwal>
                      </b-collapse>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <AddBatch
        v-if="isShowForm"
        @hideForm="isShowForm = false"
        :idJadwal="idjadwal"
        :level="dataJadwal.j_materi"
        :tanggalMulai="dataJadwal.j_tanggal_mulai"
        :tanggalSelesai="dataJadwal.j_tanggal_sampai"
      />
    </template>
  </Layout>
</template>
